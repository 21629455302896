<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5 class="mb-4">
								<strong>Cuentas por Pagar</strong>
							</h5>
						</div>
						<div class="col-md-6 text-right">
							<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
						</div>
						<div class="col-md-12">
							<hr class="hrText" data-content="♦" />
						</div>
					</div>
					<a-table :columns="columns" :dataSource="debtsToPayList" rowKey="id">
						<span slot="action" slot-scope="record">
							<a-button type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
						</span>
					</a-table>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
//
import { mapGetters } from 'vuex'

export default {
	name: 'debtsToPayView',
	data() {
		return {
			searchText: '',
			columns: [
				{
					title: 'ODC',
					dataIndex: 'purchase_order_id',
					align: 'center',
				},
				{
					title: 'Proveedor',
					dataIndex: 'short_name',
				},
				{
					title: 'Monto total',
					dataIndex: 'total',
					align: 'center',
				},
				{
					title: 'Monto pagado',
					dataIndex: 'paid',
					align: 'center',
				},
				{
					title: 'Monto por pagar',
					dataIndex: 'to_pay',
					align: 'center',
				},
				{
					title: 'Fecha de creación',
					dataIndex: 'created_at',
					align: 'center',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
				},
			],
		}
	},
	computed: {
		...mapGetters('debtsToPay', ['debtsToPayList']),
		spinnerLoaderLabel() {
			return this.$store.state.debtsToPay.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.debtsToPay.spinnerLoader
		},
	},
	mounted() {
		if (!this.debtsToPayList.lenght) {
			this.initModule()
		}
	},
	methods: {
		initModule() {
			this.$store.dispatch('debtsToPay/GET_ALL')
		},
		onEditRecord(id) {
			this.$store.dispatch('debtsToPay/GET', id)
		},
	},
}
</script>
